import '../../support-form.scss';

import { Loader, SupportForm } from '@bcmi-labs/react-components';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { IconNavigationArrowUpRight } from '@bcmi-labs/react-icons';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import contactFormJson from '../../../../content/en/support-form/support-form.json';

const StoreSupport = props => {
  const API_URL = 'https://api2.arduino.cc';

  const [formConfig, setFormConfig] = useState(null);

  const ref = useRef(null);

  const embed =
    typeof window !== 'undefined' && (window.self !== window.top || window.location.href.includes('?embed'));

  const formClasses = ['support-form', 'page', (embed && 'embed') || ''].join(' ');

  useLayoutEffect(() => {
    if (window && ref && ref.current) {
      window.parent.postMessage(JSON.stringify({ height: ref.current.clientHeight }), '*');
    }
  }, [formConfig]);

  useEffect(() => {
    fetch(`${API_URL}/countries/v1`)
      .then(response => response.json())
      .then(countries => {
        if (!countries) {
          return;
        }

        // make the modal smaller if included in iframes
        if (embed) {
          contactFormJson.title = null;
        }

        // augment the contactForm, adding the countries
        contactFormJson.fields = contactFormJson.fields.map(fieldRow => {
          return fieldRow.map(field => {
            if (field.id === 'country_consent') {
              field.values = countries.map(country => {
                return {
                  value: country.code,
                  label: country.name,
                  tags: [country.code],
                };
              });
            }
            return field;
          });
        });

        // augment the contactForm, adding the footer
        contactFormJson.footer = (
          <>
            <div className="footercard">
              <a className="footercard--title" href="https://forum.arduino.cc/" target="_blank" rel="noreferrer">
                Join the Community <IconNavigationArrowUpRight />
              </a>
              <p className="footercard--description">
                If you need help with your project or if you want to contribute.
              </p>
            </div>
            <div className="footercard">
              <a
                className="footercard--title"
                href="https://www.arduino.cc/en/become-distributor/"
                target="_blank"
                rel="noreferrer"
              >
                Become a Distributor <IconNavigationArrowUpRight />
              </a>
              <p className="footercard--description">Take the steps to become a certified Arduino distributor.</p>
            </div>
            <div className="footercard">
              <a
                className="footercard--title"
                href="https://www.arduino.cc/pro/contact-us"
                target="_blank"
                rel="noreferrer"
              >
                Business Partnerships <IconNavigationArrowUpRight />
              </a>
              <p className="footercard--description">If you would like to propose a partnership or business deal.</p>
            </div>
          </>
        );

        setFormConfig(contactFormJson);
      });
  }, [embed]);

  if (!formConfig) {
    return <Loader />;
  }

  return (
    <Layout currentLocale="en">
      <SEO title="Contact us" />
      <main className={formClasses} ref={ref}>
        <section>
          <div className="page-content">
            <div id="store-support">
              <SupportForm config={formConfig} />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default StoreSupport;
